<template>
    <div class="main-menu cursor-pointer">
        <sub-menu-container class="main-menu-container" >
            <div class="welcome-area cursor-pointer">
                <div class="welcome-area-content">
                    <span class="h-100">
                        <icon-person-circle width="23"/>
                        <h5 class="title-16 mb-0 text-capitalize" style="margin-top: 0 !important; margin-left: 9px !important; display:inline-block">
                            {{ isLogged ? `${$t("layout.hi")}  ${ userData.entity.first_name.toLowerCase()}, ${$t("layout.welcome")}!` :$t("layout.welcome") +'!'}}
                        </h5>
                    </span>
                </div>
            </div>
            <account-sub-menu class="account-menu" @openConfirmLogout="$emit('openConfirmLogout')" @openLogin="$emit('openLogin')" @openSignIn="$emit('openSignIn')"/>
            <hr class="submenu-divider"/>
            <div class="d-flex flex-column">
                <div class="d-flex">
                    <h5 class="title-16">{{ $t("layout.search_by_category") }}</h5>
                </div>
            </div>
            <ul class="submenu-list ps-0 mb-0 mt-1 categories-list">
                <li v-for="({address, icon, style, text, value}) in categoriesENV" :key="address"
                class="d-flex list-item cursor-pointer align-items-center" @click.prevent="navigateTo({title: text,value})">
                    <component :is="icon" :width="16" height="16" class="list-icon fill-dark-gray-200" :style="style"/>
                    <span class="list-subtitle size-15 color-dark-gray-200 weight-400">{{$t(`layout.category.${text}`)}}</span>
                    <icon-arrow :width="10" class="arrow-animation"/>
                </li>
            </ul>
        </sub-menu-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name:'TheMainMenu',
    components: {
        IconMenu: () => import('@/modules/layout/assets/svg/iconMenu.vue'),
        IconPersonCircle: () => import('@/common/svg/iconPersonCircle.vue'),
        IconOrder: () => import("@/common/svg/iconOrder.vue"),
        IconArrow: () => import("@/common/svg/iconArrow"),
        IconCategories: () => import("@/common/svg/iconCategories"),   
        // 
        IconCable: () => import('@/common/svg/categories/iconCable'),
        IconCellphone: () => import('@/common/svg/categories/iconCellphone'),
        IconCharger: () => import('@/common/svg/categories/iconCharger'),
        IconHeadphone: () => import('@/common/svg/categories/iconHeadphone'),
        IconSpeaker: () => import('@/common/svg/categories/iconSpeaker'),

        IconBattery: () => import('@/modules/home/assets/svg/Categories/iconBattery'),
        IconCamera: () => import('@/modules/home/assets/svg/Categories/iconCamera'),
        IconComputer: () => import('@/modules/home/assets/svg/Categories/iconComputer'),
        IconConfiguration: () => import('@/modules/home/assets/svg/Categories/iconConfiguration'),
        IconGlass: () => import('@/modules/home/assets/svg/Categories/iconGlass'),
        IconHandsFree: () => import('@/modules/home/assets/svg/Categories/iconHandsFree'),
        IconLightRing: () => import('@/modules/home/assets/svg/Categories/iconLightRing'),
        IconPhoneCase: () => import('@/modules/home/assets/svg/Categories/iconPhoneCase'),
        IconPowerBank: () => import('@/modules/home/assets/svg/Categories/iconPowerBank'),
        IconSIM: () => import('@/modules/home/assets/svg/Categories/iconSIM'),
        IconSecurityCamera: () => import('@/modules/home/assets/svg/Categories/iconSecurityCamera'),
        IconStorage: () => import('@/modules/home/assets/svg/Categories/iconStorage'),
        IconTablet: () => import('@/modules/home/assets/svg/Categories/iconTablet'),
        IconTools: () => import('@/modules/home/assets/svg/Categories/iconTools'),
        IconTouch: () => import('@/modules/home/assets/svg/Categories/iconTouch'),

         //general container
        SubMenuContainer: () => import('@/modules/layout/components/navbar/menus/submenus/SubMenuContainer.vue'),
        //submodules
        AccountSubMenu: ()=> import('@/modules/layout/components/navbar/menus/submenus/AccountSubMenu.vue')
    },
    computed: {
        ...mapGetters({
            userData: "auth/userData",
            isLogged: "auth/isLogged",
            quantity: "cart/quantity",
            branchSelected: "branches/branchSelected",
            addressSelected: "addresses/getSelected",
            isPageDisable: "page/isPageDisable"
        }),
        categoriesENV(){
            if(process.env.VUE_APP_VIDVIE == 1){
                return this.categoriesUS
            } else {
                return this.categoryOptions
            }
        },
    },
    methods: {
        navigateTo(category){
            this.$store.commit("products/SET_CATEGORY", category);
            this.$router.replace({path: '/shop'}).catch(() => {})
        },   
    },
    data() {
        return {
            categoryOptions: [
                {
                    viewBox: '0 0 250 250',
                    icon: 'icon-headphone',
                    style: 'margin-top: 2px;',
                    text: 'headphones',
                    value: 16,
                },
                {
                    icon: 'icon-cable',
                    style:'margin-left: 3px; margin-top: 1px;',
                    text: 'cables',
                    value: 9,
                },
                {
                    icon: 'icon-cellphone',
                    style:'margin-top: 3px;',
                    text: 'cellphones',
                    value: 1,
                },
                {
                    viewBox: '20 0 250 250',
                    icon: 'icon-speaker',
                    style:'margin-top: 3px;',
                    text: 'speakers',
                    value: 5,
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 11,
                    text: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 9,
                    text: 'cables'
                },
                {
                    icon: 'IconCellphone',
                    viewBox: "0 0 250 250",
                    value: 1,
                    text: 'cellphones'
                },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 5,
                    text: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 3,
                    text: 'batterys'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "30 10 200 200",
                    value: 11,
                    text: 'charging_cube'
                },
                //
                {
                    icon: 'IconSecurityCamera',
                    viewBox: "0 0 200 200",
                    value: 36,
                    text: 'security_cameras'
                },
                {
                    icon: 'IconCamera',
                    viewBox: "0 0 200 200",
                    value: 36,
                    text: 'cameras'
                },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 12,
                    text: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 25,
                    text: 'touch'
                },
                {
                    icon: 'IconHandsFree',
                    viewBox: "0 0 200 200",
                    value: 16,
                    text: 'handsfree'
                },
                {
                    icon: 'IconComputer',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'computers'
                },
                {
                    icon: 'IconTools',
                    viewBox: "0 0 200 200",
                    value: 31,
                    text: 'tools'
                    },
                {
                    icon: 'IconLightRing',
                    viewBox: "0 0 200 200",
                    value: 31,
                    text: 'light_ring'
                },
                {
                    icon: 'IconStorage',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'storage'
                },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 40,
                    text: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 34,
                    text: 'glass'
                },
                {
                    icon: 'IconConfiguration',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'config'
                },
                {
                    icon: 'IconTablet',
                    viewBox: "0 0 200 200",
                    value: 33,
                    text: 'tablet'
                },
                {
                    icon: 'IconSIM',
                    viewBox: "0 0 200 200",
                    value: 47,
                    text: 'SIM'
                }
            ],
            categoriesUS: [
                {
                    icon: 'IconHeadphone',
                    viewBox: "0 0 250 250",
                    value: 4,
                    text: 'headphones'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 3,
                    text: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 	2,
                    text: 'cables'
                },
                // {
                //     icon: 'IconCellphone',
                //     viewBox: "0 0 250 250",
                //     value: 1,
                //     name: 'cellphones'
                // },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 8,
                    text: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 6,
                    text: 'batterys'
                },
                // {
                //     icon: 'IconSecurityCamera',
                //     viewBox: "0 0 200 200",
                //     value: 36,
                //     name: 'security_cameras'
                // },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 17,
                    text: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 21,
                    text: 'touch'
                },
                // {
                //     icon: 'IconTools',
                //     viewBox: "0 0 200 200",
                //     value: 31,
                //     name: 'tools'
                // },
                // {
                //     icon: 'IconStorage',
                //     viewBox: "0 0 200 200",
                //     value: 19,
                //     name: 'storage'
                // },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 5,
                    text: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 14,
                    text: 'glass'
                },
                // {
                //     icon: 'IconTablet',
                //     viewBox: "0 0 200 200",
                //     value: 33,
                //     name: 'tablet'
                // },
                // {
                //     icon: 'IconSIM',
                //     viewBox: "0 0 200 200",
                //     value: 27,
                //     name: 'SIM'
                // },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
$smc-top-position: 45px;
.main-menu{
    z-index: 13;
}
.main-menu-container {
    position: absolute;
    
    width: 380px !important;
    top: $smc-top-position;
    left: 0px;
    
    //triangle
    transition: $transition-2;

    animation: show-submenu-container-main-menu;
    animation-duration: 2s;
    animation-fill-mode: both;
    .account-menu{
        width: auto !important;
    }
}
@keyframes show-submenu-container-main-menu {
    0% {
        top: calc($smc-top-position + 20px); //needs to be applied manually...
        opacity: 0;
    }
    25% {
        top: $smc-top-position; //needs to be applied manually...
        opacity: 1;
    }
}
.welcome-area {
    margin: 0px -16px;
    margin-bottom: 10px; 
    margin-top: -16px; 
    box-shadow: 3px 1px 6px #7C879333;
    .welcome-area-content{
        padding: 16px 16px;
    }
}
.categories-list{
    max-height: 150px;
    overflow: scroll;
    scrollbar-color: var(--gray-100) #edf2f500;
}

</style>
