<template>
    <svg :width="size + 'px'" fill="#ffffff00" viewBox="0 0 146 132">
        <path d="M139 66H7" :stroke="color" stroke-width="18" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.7868 110.787L7 66L51.7868 21.2132" :stroke="color" stroke-width="18" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
name: 'iconBackArrow',
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
}
}
</script>

<style scoped>

</style>
