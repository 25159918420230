<template>
<div>
    <div class="d-flex flex-column align-items-center">
        <icon-cart-empty :size="95" class="icon-empty-cart" />
        <h6 class="size-16 text-center weight-700 mb-0">{{$t("cart.empty_cart")}}</h6>
        <p class="size-14 color-dark-gray-200 text-center weight-500 mx-3 my-1 ">{{$t("cart.your_cart_at_the_branch")}} <b>{{(branchSelected != undefined ? branchSelected.description:'') | textFormat}}</b> {{$t("cart.it_is_empty")}} :(</p>
        <router-link to="/shop" class="btn-container" tag="div">
            <main-button class="btn mt-2" :title="'cart.go_shopping'" icon>
                <icon-search :size="14" class="icon" />
            </main-button>
        </router-link>
    </div>
</div>

</template>

<script>
import MainButton from '../../../../../../../common/components/buttons/MainButton.vue'
import IconSearch from '../../../../../../../common/svg/iconSearch.vue'
import iconCartEmpty from '../../../../../assets/svg/iconCartEmpty.vue'
import { mapGetters } from "vuex"
export default {
    components: { iconCartEmpty, MainButton, IconSearch },
    name: 'CartEmpty',
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected",
        }),
    },
}
</script>

<style lang="scss" scoped>
    .icon-empty-cart {
        margin-top: -10px;
    }

    h6 {
        line-height: 1;
    }

    .btn-container{
        svg {
                fill: white;
        }
        &:hover {
            svg {
                fill: white;
            }
        }
    }

    .btn {
        line-height: 2;
        svg {
            margin-right: 10px !important; 
        }
    }

</style>
