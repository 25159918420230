<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" confirm-title="layout.delete_history" customDescription :isQuestion="false">
        <icon-delete :size="100"/>
        <template #customDescription>
            <div>
                <p class="color-dark-gray-200">
                    {{$t('layout.delete_history_desc_1')}}
                    <b class="weight-600">{{historyToDelete}}</b>
                    {{$t('layout.delete_history_desc_2')}}
                </p>
            </div>
        </template>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../../../common/components/modals/MainConfirm.vue'
import IconDelete from '../../../../../cart/assets/svg/iconDelete.vue'
import { mapGetters } from "vuex"
export default {
    components: {IconDelete, MainConfirm },
    name: "ConfirmDeleteHistory",
    computed: {
        ...mapGetters({
            searchHistory: "products/searchHistory"
        }),
        historyToDelete(){
            let foo;
            this.searchHistory.map((history) => {
                if (history.id === this.historyId) {
                    foo = history.description;
                    return;
                }
            });
            return foo;
        }
    },
    props: {
        historyId: {
            type: String
        }
    }
}
</script>