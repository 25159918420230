<template>
  <nav class="fixed-top navbar-expand-lg zindex-fixed" :class="{'navbar-shadow': isTop, 'active-customer' : employeeActiveCode}" 
  :style="{backgroundColor: employeeActiveCode ? '#000' : 'var(--dark-main-navy)'}">
    <div class="container h-100 d-flex align-items-center align-content-center">
      <div class="row w-100 mx-0" >
        <div class="d-flex col-xl-8 px-0 align-content-center align-items-center" :class="[showEmployeeOption ? 'col-5 col-sm-7 col-lg-9' : 'col-9 col-sm-10']">
          <!-- desktop main-menu -->
          <div v-if="viewPort('lg')" class="menu-item main-menu-hover d-none d-lg-flex position-relative" @mouseover="menuMask = true" @mouseout="menuMask = false" >
            <div class="red-underline cursor-pointer" :style="{'z-index': menuMask ? '13':'0'}">
              <icon-menu color="white" />
            </div>
            <the-main-menu @mouseover.native="menuMask = true" @mouseout.native="menuMask = false" @openConfirmLogout="confirmLogout = true" @openLogin="openLogin"  @openSignIn="openRegister" :class="{'mask-focused': menuMask}" />
          </div>
          <!-- mobile main-menu -->
          <div v-if="viewPort('lg', {max: true})" class="d-block d-lg-none" :style="{'z-index': drawerMask ? '13':'0'}">
            <icon-menu color="white" class="cursor-pointer" @click.native="setDrawerMask" />
            <drawer :showDrawer="drawerMask" @openConfirmLogout="confirmLogout = true" @openLogin="openLogin" @openSignIn="openRegister" @closeDrawer="drawerMask = false" />
          </div>
          <!-- common-logo -->
          <router-link to="/" class="logo-container px-0" @click.native="scrollUp" :class="{'logo-container-employee': showEmployeeOption}">
            <img alt="Mundo Movil" :src="vidvie ? require('../../../../common/assets/images/logo-vidvie.png') : require('../../../../common/svg/logo.svg')" />
          </router-link>
          <header-search class="d-none d-lg-flex ps-3" :class="{'mask-focused': searchMask}" @searchMask="setSearchMask" :openHistory="searchMask"/>
        </div> 
        <the-secondary-menu :viewPort="viewPort" class="col-sm-2 col-xl-4 px-0 ms-auto" @openConfirmLogout="confirmLogout =  true" @openLogin="openLogin" 
        @openSignIn="openRegister" @toggleSearchBar="mobileSearch = !mobileSearch" :mobileSearch="mobileSearch" :class="[showEmployeeOption ? 'col-7 col-sm-5 col-md-4 col-lg-3' : 'col-3 col-lg-1']"
        />
        <transition name="mobile-search-transition">
          <mobile-search v-if="mobileSearch" @toggleSearchBar="mobileSearch = false;" @deleteHistory="(id)=> {historyId = id; deleteHistory = true}"/>
        </transition>
      </div>
    </div>
    <div class="position-absolute">
      <confirm-delete-history v-if="deleteHistory" @closeModal="deleteHistory = false" @confirmDelete="removeFromHistory" :historyId="historyId" style="z-index: 20;" />
      <login v-if="(loginModal || stateModal) && !mobile && !quickView" @closeModalLogin="closeLogin" :asRegister="registerModal" />
      <confirm-logout v-if="confirmLogout" @closeModalLogout="confirmLogout = false" style="z-index: 13;"/>
      <quotation-created :quotationID="quoteCreated.quotation" @closeModal="quoteCreated.show = false" v-if="quoteCreated.show"/>
      <div class="mask" v-if="menuMask || searchMask || drawerMask" @click="drawerMask = false; searchMask = false;" />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex"
import ConfirmLogout from '../../../auth/components/ConfirmLogout.vue';
import Login from '../../../auth/components/Login.vue';
import QuotationCreated from '../../../cart/components/modals/QuotationCreated.vue';
import IconMenu from '../../assets/svg/iconMenu.vue';
import Drawer from './drawer/Drawer.vue';
import TheMainMenu from './menus/TheMainMenu.vue';
import TheSecondaryMenu from './menus/TheSecondaryMenu.vue';
import HeaderSearch from './search/HeaderSearch.vue';
import ConfirmDeleteHistory from './search/mobile-search/ConfirmDeleteHistory.vue';
import MobileSearch from './search/mobile-search/MobileSearch.vue';

export default {
    name: 'MainNavbar',
    components: {
      IconMenu,
      //modals
      ConfirmDeleteHistory,
      Login,
      ConfirmLogout,
      //mobile
      Drawer,
      //search
      MobileSearch,
      HeaderSearch,
      TheSecondaryMenu,
      TheMainMenu,
      QuotationCreated,    
    },
    data(){
      return {
        historyId: null,
        isListOpen: false,
        isActiveCart: false,
        showNav: false,
        showSearch: false,
        isTop: false,
        //modal
        loginModal: false,
        confirmLogout: false,
        registerModal: false,
        deleteHistory: false,
        ///mask
        menuMask: false,
        searchMask: false,
        drawerMask: false,
        //mobile sections
        mobileSearch: false,
      }
    },
    computed: {
      ...mapGetters({
        isLogged: "auth/isLogged",
        user: "auth/userData",
        stateModal: "auth/stateModal",
        isPageDisable: "common/isPageDisable",
        vidvie: "home/vidvie",
        mobile: 'common/mobile',
        quickView: "products/quickView",
        employeeActiveCode: 'auth/employeeActiveCode',
        employee: 'auth/employee',
        quoteCreated: 'cart/quoteCreated'
      }),
      showEmployeeOption(){
        return this.employee?.entity?.activated_employee;
      }
    },
    methods: {
      scrollUp(){
        if(this.$route.path == '/')
        window.scroll(0, 0);
      },
      handleScroll () {
        this.isTop = window.scrollY > 40;
      },
      setSearchMask(data) {
        this.searchMask = data
      },
      setDrawerMask() {
        this.drawerMask = !this.drawerMask
      },
      closeLogin(){
        this.loginModal = false;
        this.registerModal = false;
        this.$store.commit("auth/OPEN_MODAL", false)
      },
      openRegister(){
        if(this.isPageDisable){
            this.$store.commit("common/SET_DISABLE_MODAL", true)
            return
        }
        this.loginModal = true
        this.registerModal = true;
        //remove current drawerMask
        this.drawerMask = false
      },
      openLogin(){
          if(this.isPageDisable){
              this.$store.commit("common/SET_DISABLE_MODAL", true)
              return
          }
          this.loginModal =  true
      },
      checkWindowSize(){
        const lg = window.matchMedia("(min-width: 992px)").matches ? true : false;
        if (lg) {
          this.drawerMask = false;
          this.mobileSearch = false;
        }
      },
      async removeFromHistory(){
        await this.$store.dispatch("products/removeFromHistory", this.historyId)
        this.deleteHistory = false;
      },
    },
    watch: {
      loginModal: function() {
        this.drawerMask = false;
      },
      confirmLogout: function() {
        this.drawerMask = false;
      },
      '$route': function() {
        this.drawerMask = false;
      },
      drawerMask(mask) {
        if (mask)
        this.mobileSearch = false;
      }
    },
    created () {
      window.addEventListener("resize", this.checkWindowSize);
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener("resize", this.checkWindowSize);
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

</script>

<style scoped>
/* only appears when the user scrolls down*/
.navbar-shadow {
  box-shadow: 0px 4.5px 10px -2px #4e555f6e;
}
nav {
  height: 64px;
  padding: 10px;
  z-index: 10;
  top: -5px;
}
.active-customer{
  border-bottom: 3px solid #ffd100;
}
nav .main-menu-hover:hover:before {
  width: 80px;
  height: 64px;
  z-index: 13;
  background: transparent;
  cursor: pointer;
}
.icon-person, .icon-order {
  margin-right: 10px;
}
.logo-container {
  margin-left: 40%;
}
.logo-container-employee{
  margin-left: 10%;
}
.logo-container img {
  width: 100px;
}
.menu-item:hover::v-deep .sub-menu-container {
  display: block;
}
.menu-item:hover::before {
  display: inline-block;
  position: absolute;
  content: '';
  height: 67px;
  background-color: transparent;
}
.mobile-search-transition-enter-active, .mobile-search-transition-leave-active {
  transition: all .3s var(--alt-ease-in-out);
  transform: scale(1);
}
.mobile-search-transition-enter, .mobile-search-transition-leave-to {
  opacity: 0;
  transform: scale(1.03);
}
@media (min-width: 576px) {
  .logo-container {
    margin-left: 45%;
  }
}
@media (min-width: 992px) {
  .logo-container {
    margin-left: 1.8rem;
  }
}
@media (max-width: 576px) {
  .container {
    padding-inline: 2px;
  }
}
</style>
