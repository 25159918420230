<template>
  <div class="secondary-menu d-flex justify-content-end justify-content-between align-content-center align-items-center position-relative">
    <!-- Select Language -->
    <select-language class="menu-item lang-item d-none d-xl-flex" />
    <!-- Account -->
    <div class="menu-item account-item mt-2" :class="{'active-red-underline': setRoute('/account')}">
      <router-link :to="employeeActiveCode ? '/account/quotes' : '/account'" class="d-flex align-content-center align-items-center justify-content-start cursor-pointer position-relative" 
      @mouseover.native="showAccountHint = 'show-end'">
        <div class="d-none d-lg-block">
          <div class="account-icon-caret d-flex align-items-center" :class="{'caret-down-triangle': viewPort() == 'lg' && !renderAccountMenuHint}">
            <div class="red-underline-icon align-items-center h-100">
              <icon-person :size="21" :class="[employeeActiveCode? 'fill-blue-600' :'fill-white']" />
              <div v-if="!isLogged" class="mobile-hint triangle position-absolute" :class="{'d-block': showAccountHint === 'show-default' && viewPort() === 'lg' }"></div>
            </div>
          </div>
        </div>
        <div class="d-none d-xl-flex flex-column align-items-start ms-2">
          <span class="account-greetings d-block color-light-gray-50 text-limited text-capitalize" 
          data-toggle="tooltip" data-placement="top" :title="isLogged ? fullName:''" :class="{'active-employee' : employeeActiveCode}">
            {{ !isLogged ? $t("layout.welcome"): employeeActiveCode ? fullName.toLowerCase() : $t("layout.hi")+" "+fullName.toLowerCase()}}
          </span>
          <div class="d-flex">
            <span class="color-light-gray-50 size-12 weight-600 red-underline">
              {{$t("layout.account.account")}}
            </span>
            <div class="d-flex" :class="{'caret-down-triangle': !renderAccountMenuHint}">
              <icon-caret-down class="fill-white ms-2" :size="10"/>
              <div class="triangle position-absolute" v-if="!isLogged && !mobile" :class="{'d-block': showAccountHint == 'show-default' && (viewPort() == 'xl' || viewPort() == 'xxl')}" />
            </div>
          </div>
        </div>
        <div id="step-1-get-started"></div>
      </router-link>
      <sub-menu-container v-if="!renderAccountMenuHint" class="account-menu">
        <account-sub-menu @openConfirmLogout="$emit('openConfirmLogout')" @openLogin="$emit('openLogin')" @openSignIn="$emit('openSignIn')" />
      </sub-menu-container>
      <account-hint :class="[showAccountHint]" v-if="!isLogged && !mobile" v-click-outside="closeAccountHint" @mouseover.native="keepAccountHint" @mouseleave.native="showAccountHint = 'show-end'" @openLogin="$emit('openLogin')" @openSignIn="$emit('openSignIn')" />
    </div>
    <!-- Order -->
    <router-link to="/account/orders" class="menu-item order-item mt-2 d-none d-xl-flex align-content-center align-items-center justify-content-start cursor-pointer" 
    :class="{'active-red-underline': setRoute('/account/orders')}" v-if="!showEmployeeOption">
      <div class="d-flex align-content-center justify-content-start" style="width: 20px;">
        <icon-order :size="20" class="fill-light-gray-50 mb-1" />
      </div>
      <div class="d-flex flex-column align-items-start ms-2" to="/account/orders">
        <span class="color-light-gray-50 text-limited" style="max-width: 82%; font-size: 10px; line-height: 1.2;"> {{ $t("layout.refund") }}</span>
        <span class="color-light-gray-50 size-12 weight-600 red-underline">{{ $t("&")}} {{ $t("layout.orders")  }}</span>
      </div>
    </router-link>
    <!-- Search -->
    <div @click="$emit('toggleSearchBar')" class="menu-item search-item d-flex d-lg-none align-items-center color-white me-3 cursor-pointer">
      <div class="red-underline" :class="{'active': mobileSearch}">
        <icon-search color="var(--white)" />
      </div>
    </div>
    <!-- Cart  -->
    <div v-if="!mobile" class="menu-item cart-item d-flex cursor-pointer mt-2" :class="{'active-red-underline': setRoute('/cart')}">
      <router-link :to="{path: '/cart'}" class="cart-item-hover position-relative" @mouseenter.native="$store.commit('common/RESET_NOTIFY')" @click.native="getCart" id="icon-cart">
        <div class="red-underline">
          <div class="cart-icon-caret" :class="{'caret-down-triangle': viewPort('lg'), 'show-caret': notify.show && !notify.quick, 'remove-caret': cartCaret}" style="width: 24px;">
            <icon-cart :size="24" class="fill-light-gray-50" />
            <span class="red-badge bg-main-red" :style="{'padding-right': quantityProducts > 9 ? '1px':'0px'}" >
              <p class="size-12 color-white weight-400 text-center" :class="{'ninety-more': quantityProducts > 99}">
                {{quantityProducts > 99 ? '99+' : quantityProducts}}
              </p>
            </span>
          </div>
        </div>
      </router-link>
      <sub-menu-container v-show="viewPort('lg')" class="cart-menu" :style="{paddingRight: cartProducts.length >= 4 ? '7px': '15px'}">
        <cart-sub-menu-container @openConfirm="openConfirm" @buy="buy" :loading="loading" @createQuotation="createQuotation"/>
      </sub-menu-container>
      <main-notify v-if="notify.show && !notify.quick" @removeCartTriangle="cartCaret = true" />
    </div>
    <!-- Wishlist -->
    <div v-if="!showEmployeeOption" class="menu-item disappear wishlist-item d-none d-xl-flex align-items-center justify-content-end cursor-pointer mt-1 me-md-0 me-lg-0" 
    :class="{'active-red-underline': setRoute('/account/wishlist') }" style="width: 22px;" >
      <router-link to="/account/wishlist">
        <div class="red-underline">
          <icon-favourite :size="22" class="fill-light-gray-50 active-heart" />
        </div>
      </router-link>
    </div>
    <div v-if="showEmployeeOption" class="position-relative d-flex">
      <main-input id="customer-code" placeholder="auth.code" type="text" :value="customerCode" @changeValue="(data) => {customerCode = data}" 
        :class="{active: employeeActiveCode}" @enter="loginCustomer" :disabled="employeeActiveCode">
        <icon-code :size="24" color="var(--main-red)"/>
      </main-input>
      <div class="position-absolute input-loading d-flex align-items-center justify-content-center" v-if="loadingCode">
        <half-circle-spinner :animation-duration="1000" :size="20" color="var(--main-red)"/>
      </div>
      <main-button icon class="px-2 btn-remove-customer" :title="''" @click="logoutFunction" v-if="employeeActiveCode">
        <icon-close :color="'#fff'" :size="14"/>
      </main-button>
    </div>
    <div v-if="showEmployeeOption" @click="showClientTableModal = true">
          <icon-search :size="18" :color="'var(--gray-1001)'" class="hover-main-red"/>
    </div>
    <div v-if="$route.path != '/shop'" v-show="viewPort('lg')"  @click="enterOffes()" class="menu-item positionRibbon cursor-pointe">
      <ribbonMenu style="z-index: 10;"></ribbonMenu>
    </div>
    <confirm-logout-customer v-if="logoutCustomer" @closeModalLogout="logoutCustomer = false" @confirm="removeCustomer" :loading="loadingCode"/>
    <confirm-delete-product v-if="showConfirmDeleteProduct" @openConfirm="openConfirm" @confirmDelete="deleteConfirmed" @closeModal="showConfirmDeleteProduct = false" :loading="loadingDelete"/>    
    <error-cart v-if="error.show" @closeModal="error.show = false" title="Error procesando su orden" :description="error.description" />
    <quantity-alert v-if="quantityNotAvailable" :products="productsError" :existence="existenceByBranch" @closeModal="quantityNotAvailable = false"/>
    <select-branch v-if="showSelectBranch" @closeModal="showSelectBranch = false" />
    <client-table-vue v-if="showClientTableModal" :customerCode="customerCode" @closeModal="showClientTableModal = false"/>
  </div>
</template>

<script>
import CartSubMenuContainer from './submenus/cart-submenu/CartSubMenuContainer.vue'
import MainNotify from '@/common/components/notifications/MainNotify.vue'
import ErrorCart from '../../../../cart/components/modals/ErrorCart.vue'
import iconSearch from '../../../../../common/svg/iconSearch.vue';
import { cartMixin } from '../../../../cart/helpers/mixins/cart-mixin';
import { quotesMixin } from '../../../../account/helpers/mixins/quotes-mixin';
import { mapGetters } from "vuex"
import QuantityAlert from '../../../../cart/components/QuantityAlert.vue';
import MainInput from '../../../../../common/components/inputs/MainInput.vue';
import IconCode from '../../../../account/assets/svg/iconCode.vue';
import { HalfCircleSpinner } from 'epic-spinners'
import MainButton from '../../../../../common/components/buttons/MainButton.vue';
import IconClose from '../../../../../common/svg/iconClose.vue';
import ConfirmLogoutCustomer from '../../../../auth/components/ConfirmLogoutCustomer.vue';
import SelectBranch from '../../../../branches/components/SelectBranch.vue';
import ClientTableVue from '../../../../account/components/profile/ClientTable.vue'
import RibbonMenu from './RibbonMenu.vue'


export default {
  name: 'TheSecondaryMenu',
  components: {
    IconCaretDown: () => import("@/common/svg/iconCaretDown.vue"),
    IconPerson: () => import("@/common/svg/iconPerson.vue"),
    IconOrder: () => import("@/common/svg/iconOrder.vue"),
    IconCart: () => import("@/common/svg/iconCart.vue"),
    IconFavourite: () => import("@/common/svg/iconFavourite.vue"),
    IconSearch: () => import("@/common/svg/iconSearch.vue"),
    SubMenuContainer: () => import("@/modules/layout/components/navbar/menus/submenus/SubMenuContainer.vue"), //general container
    AccountSubMenu: () => import("@/modules/layout/components/navbar/menus/submenus/AccountSubMenu.vue"), //submodules
    SelectLanguage: () => import("@/common/components/selects/SelectLanguage.vue"), //select-language
    ConfirmDeleteProduct: () => import("@/modules/products/components/modals/ConfirmDeleteProduct.vue"),
    AccountHint: () => import("@/modules/layout/components/navbar/menus/submenus/AccountHint.vue"),
    CartSubMenuContainer,
    MainNotify,
    ErrorCart,
    QuantityAlert,
    iconSearch,
    ClientTableVue,
    MainInput,
    IconCode,
    HalfCircleSpinner,
    MainButton,
    RibbonMenu,
    IconClose,
    ConfirmLogoutCustomer,
    SelectBranch
  },
  props:{
    mobileSearch: {
      type: Boolean
    },
  },
  mixins: [cartMixin, quotesMixin],
  computed: {
    ...mapGetters({
        isLogged: "auth/isLogged",
        user: "auth/userData",
        quantityProducts:"cart/quantityProducts",
        branches: "branches/branches",
        cartProducts:"cart/getProducts",
        notify: "common/notify",
        lists: "wishlist/lists",
        mobile: 'common/mobile',
        employee: 'auth/employee',
        employeeActiveCode: 'auth/employeeActiveCode',
        selectedQuotation: "account/selectedQuotation",
        changedQuotes: "account/changedQuotes",
        getProducts: "cart/getProducts",
        filters: "products/filters"
    }),
    accountPosition() {
      return this.isLogged ? `account-position-logged-${this.$i18n.locale}`: `account-position-unlogged-${this.$i18n.locale}`
    },
    fullName(){
      return this.$options.filters.fullName(this.user.entity);
    },
    showEmployeeOption(){
      return this.employee?.entity?.activated_employee;
    }
  },
  data() {
    return {
      showClientTableModal: false,
      selectedProduct: null,
      showConfirmDeleteProduct: false,
      confirmLogout: false,
      renderAccountMenuHint: true,
      showAccountHint: 'show-start',
      startcounter: null,
      endcounter: null,
      counter: null,
      cartCaret: false,
      productsError: [],
      quantityNotAvailable: false,
      loading: false,
      loadingCode: false,
      customerCode: null,
      loadingDelete: false,
      logoutCustomer: false,
      showSelectBranch: false
    }
  },
  watch: {
    //In case we are already on this page we will load the product on the url again
    '$route': function() {
      this.drawerMask = false;
    },
    showAccountHint(val){
      if(val == 'show-end'){
        this.endcounter = setTimeout(() => {
          this.renderAccountMenuHint = false;
        }, 300)
      }
    },
    notify: function ({show}){
      if (!show) this.cartCaret = false;
    },
    employeeActiveCode(val){
      if(val){
        if(this.customerCode != this.user.username)
        this.customerCode = this.user.username
      }
    },
    'user.username'(val){
      // RESET QUOTATION WHEN CHANGE USER 
      this.$store.commit('account/SELECT_QUOTATION', null);
      // RESET CUSTOMER CODE WHEN CHANGE USER 
      if (this.customerCode != val) {
        this.customerCode = null;
      }
    },
  },
  methods: {
    enterOffes(){
      this.filters.only_offers = 1
      this.$router.replace({path: '/shop'}).catch(() => {});
    },
    setRoute(path){
      return this.$route.path === path;
    },
    async deleteConfirmed() {
      let product = {
        cod_articulo: this.selectedProduct,
        quantity: 0, 
      }
      try {
        this.loadingDelete = true
        await this.$store.dispatch("cart/removeProduct", product);
      } catch (error) {
        console.log(error)
      } finally{
        this.loadingDelete= false
      }
      this.showConfirmDeleteProduct = false;
    },
    openConfirm(codarticulo) {
      this.selectedProduct = codarticulo; //send the product_id to the mixer value
      this.showConfirmDeleteProduct = true
    },
    closeAccountHint(){
      this.showAccountHint = "show-end";
    },
    keepAccountHint(){
      clearTimeout(this.counter);
    },
    async getCart(){
      if(this.$route.path == '/cart'){
        try {
          await this.$store.dispatch("cart/getMyCart")
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loginCustomer(){
      if(!this.customerCode) return
      
      try {
        this.loadingCode = true;
        await this.$store.dispatch("auth/loginCustomer", {customer: this.customerCode.replace(' ', ''), getCart: true})
        let notify ={
          type: 'success',
          show: true,
          contentHTML: `<p class="color-dark-gray-400 subtitle-14 m-0"> ${this.$t('common.notification.data_obtained_sucessfully')}: <b class="text-capitalize">
            ${this.user.entity.first_name.toLowerCase()} ${this.user.entity.last_name.toLowerCase()}</b> 
            (<span class="color-main-navy">${this.user.entity.code}</span>)<p>`
        }
        this.$store.commit("common/SET_SHORT_NOTIFY", notify);
        if(this.branches.length > 1){
          this.showSelectBranch = true;
        }
      } catch (error) {
        this.customerCode = '';
          // (<span class="color-dark-gray-300">${this.customerCode}</span>)
        let errorData = {
          show: true,
          title: `Ocurrio un error obteniendo los datos.`,
          contentHTML: 
          `<div class="text-center color-dark-gray-300">
            <span class="ms-1">${ this.$options.filters.textFormat(error.message)}</span>
          </div>`
        }
        this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
      } finally{
        this.loadingCode = false;
        setTimeout(() => {
              if(this.mobile){
                window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
              }
        }, 1000);
      }
    },
    async removeCustomer(){
      try {
          this.loadingCode = true;
          this.customerCode = null;
          await this.$store.dispatch("auth/logoutCustomer");
          this.logoutCustomer = false
        }catch (e) {
          console.log(e)
        } finally{
          this.loadingCode = false;
          if(this.mobile){
            window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
          }
        }
    },
    logoutFunction(){
      if(this.changedQuotes.length > 0){
        this.$router.push('/account/quotes').catch(() => {})
        let error = {
            show: true,
            title: `Tienes cambios sin guardar.`,
            contentHTML: 
            `<div class="text-center color-dark-gray-300 size-15 m-auto" style="line-height: 17px;max-width: 310px;">
                <span class="mb-2 color-dark-gray-300">Por favor, guardar o descarta los cambios de la cotizacion seleccionada en carrito.</span> 
            </div>`
        }
        this.$store.commit('common/SHOW_MODAL_ERROR', error)
        return
      }
      this.logoutCustomer = true;
    },
  },
  created(){
    window.addEventListener("click", this.handleClickHint);
    if(this.employee?.entity?.activated_employee){
      if(this.employee.username != this.user.username){
          this.customerCode = this.user.username;
          this.$store.commit('auth/SET_STATE_EMPLOYEE_ACTIVE_CODE', true)
      }
    }
  },
  mounted(){
    this.startcounter = setTimeout(() => {
      this.showAccountHint = "show-default"
    }, 1500);
    this.counter = setTimeout(() => {
      this.showAccountHint = "show-end"
    }, 12500);
    if(this.employeeActiveCode){
      this.customerCode = this.user.username
    }
  },
  destroyed() {
    clearTimeout(this.startcounter)
    clearTimeout(this.counter)
    clearTimeout(this.endcounter)
  },
}
</script>

<style scoped>
.secondary-menu {
  max-width: 350px;
}
.menu-item:active .red-underline-icon:after, .active:after {
  transform: scaleX(1);
}
.menu-item:hover::before {
  display: inline-block;
  position: absolute;
  content: '';
  height: 67px;
  background-color: transparent;
}
.menu-item:hover .sub-menu-container {
  display: block;
}
.menu-item:hover .caret-down-triangle:after, .menu-item:hover::v-deep  .caret-down-triangle:after {
  display: block;
}
.lang-item {
  bottom: 2px;
}
.lang-item .red-underline::after {
  top: 5px !important;
}
/* Account */
.account-item .account-menu {
  left: auto;
  right: 0;
  padding: 17px 15px;
  transform: scale(1) translateX(0);
}
.account-greetings {
  font-size: 10px; 
  line-height: 1.2;  
  max-width: 76px;
}
.account-greetings.active-employee{
  font-size: 13px;
  max-width: 120px;
  color: var(--blue-600) !important;
  font-weight: 900;
  animation: active-employee 0.7s infinite;
}
@keyframes active-employee {
  from{
    opacity: 0;
  }to{
    opacity: 1;
  }
}
.account-item:before {
  width: 112px;
}
.account-item .triangle {
  content: "";
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  left: auto;
  right: -4px;
  transform: translateY(23px);
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 11;
  opacity: 1;
  cursor: pointer;
  animation: show-triangle;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
@keyframes show-triangle {
  from {
    transform: translateY(37px);
    opacity: 0;
  }
  to {
    transform: translateY(23px);
    opacity: 1;
  }
}
.account-item .triangle.mobile-hint {
  transform: translateY(11px);
  right: 2px;
  animation: show-triangle-mobile;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
@keyframes show-triangle-mobile {
  from {
    transform: translateY(37px);
    opacity: 0;
  }
  to {
    transform: translateY(11px);
    opacity: 1;
  }
}
/* Order */
.order-item:before {
  width: 108px;
}
/* Search */
.search-item .red-underline:after {
  top: 6px;
  left: 1px;
}
/* Cart */
.menu-item.cart-item {
  margin-left: 0px !important;
}
.menu-item.cart-item .sub-menu-container {
  display: none;
}
.menu-item.cart-item .sub-menu-container:hover {
  display: block;
}
.cart-item {
  height: 32px;
  width: 36px;
}
.cart-item .show-caret:after {
  display: block;
}
.cart-item .remove-caret:after {
  animation: remove-caret 0.5s;
  animation-delay: 0.7s;
}
@keyframes remove-caret {
  0% {
    opacity: 1;
    transform: translateY(12px);
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}
.cart-item .cart-item-hover:hover:before, .cart-item:hover .cart-item-hover:before {
  display: inline-block;
}
.cart-item .cart-item-hover:before {
  display: none;
  position: absolute;
  right: -20px;
  content: '';
  height: 77px;
  width: 55px;
  z-index: 12;
  background-color: transparent;
}
.cart-item .cart-item-hover:hover + .sub-menu-container {
  display: block;
}
.cart-item .red-underline:after {
  left: 3px;
  top: 8px;
}
.cart-item .red-badge {
  position: absolute;
  right: -12px;
  top: -2px;
  z-index: 9;
  border-radius: 50%;

  width: 20px;
  height: 20px;

  padding-top: 1px;
}

.cart-item .red-badge p {
  font-size: 12px;
  font-family: 'Rubik' !important;
}
/* Wishlist */
.wishlist-item .red-underline::after {
  top: 8px;
}
.wishlist-item:hover .fill-light-gray-50 {
  fill: var(--main-red) !important;
}
.wishlist-item:hover a {
  z-index: 11;
}
.wishlist-item .hover-heart {
  fill: var(--main-red) !important;
  transition: var(--transition-1);
}
.active-red-underline .red-underline:after, .active-red-underline .red-underline-icon:after {
  transform: scaleX(1);
}
.active-red-underline .active-heart {
  fill: var(--main-red) !important;
}
.select-language-father-container::v-deep .caret-down-triangle, .caret-down-triangle {
  position: relative;
  z-index: 11 !important;
}
.caret-down-triangle:after, .select-language-father-container::v-deep .caret-down-triangle:after {
  content: "";
  display: none;
  position: absolute;
  width: 10px;
  height: 10px;
  top: -6px;
  right: -5px;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 30px solid transparent;
  opacity: 1;
  cursor: pointer;
  animation: show-caret-down-triangle;
  animation-duration: 500ms;
  animation-fill-mode: both;
}
@keyframes show-caret-down-triangle {
  from {
    top: 9px;
    opacity: 0;
  }
  to {
    top: -6px;
    opacity: 1;
  }
}
.caret-down-triangle.account-icon-caret:after {
  transform: translateY(9px) translateX(-7px);
}
.caret-down-triangle.cart-icon-caret:after {
  transform: translateY(12px);
  right: 0px;
  animation-fill-mode: both;
}
.positionRibbon {
      position: relative;
      left: 0px !important;
  }
@media (min-width: 1200px) and (max-width: 1355px){
  .positionRibbon {
      position: relative;
      left: -24px !important;
  }
}
@media (min-width: 576px) {
  .menu-item:hover .red-underline:after, .menu-item:hover .red-underline-icon:after {
    transform: scaleX(1);
  }
}
@media (min-width: 992px) and (max-width: 1999px) {
  .secondary-menu {
    max-width: 200px;
  }
}
@media (min-width: 992px) and (max-width: 1355px) {
  .disappear{
      display: none !important;
  }
}
@media (min-width: 1200px) {
  .secondary-menu {
    max-width: 390px;
  }
}
.main-input {
  max-width: 100px;
}
.main-input::v-deep > .input-group > .input-group-text{
  padding: 5px 8px;
  background-color: #fff;
}
.input-search{
  max-width: 130px;
}
.input-search::v-deep > .main-button{
  padding: 0px 8px;
}
.input-loading{
  left: 0;
  top: 0;
  background: #ffffff91;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  z-index: 1000;
}
.main-input::v-deep.active #customer-code{
  border-radius: 0;
}
.main-input::v-deep.active > .input-group > input:disabled,
.main-input::v-deep.active > .input-group > .input-group-text{
    background: var(--light-gray-501);
    cursor: no-drop;
}
.btn-remove-customer{
  border-radius: 7px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>