<template>
    <svg :width="size + 'px'" viewBox="0 0 107 107" fill="none">
        <path d="M100 7L7 100" stroke="#364B5D" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 7L100 100" stroke="#364B5D" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'iconDeleteSuggestion',
    props: {
        size: {
            type: Number,
            default: 20
        },
    }
}
</script>

<style scoped>

</style>
