<template>
    <div class="ribbon position-relative" style="z-index: 9;">
        <div class="ribbon-wrapper">
            <div class="ribbon-front overflow-hidden">
                <div class="glow">&nbsp;</div>
                % {{$t('product.offer'+'s')}}
            </div>
            <!-- <div class="ribbon-edge-topleft"></div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "RibbonMenu"
}
</script>

<style scoped>
.ribbon-wrapper {
	position: absolute;
    z-index: 3;
    left: 4px;
    top: -7px;
}
.ribbon-front {
    background-color: var(--main-red);
    width: 90px;
    position: relative;
    left: 8px;
    z-index: 2; 
    font: 13px bold Verdana, Geneva, sans-serif;
    padding: 1px 0px;
    color: #fff; 
    text-align: center;
    font-weight: bold;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-start-start-radius: 8px;
    border-bottom-left-radius: 8px;
    animation: shimmer 2s infinite;
}
.ribbon-front:hover {
    animation: none;
}

@keyframes shimmer {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

/* .ribbon-edge-topleft, */
.ribbon-edge-topright,
/* .ribbon-edge-bottomleft, */
.ribbon-edge-bottomright {
	position: absolute;
	z-index: 1;
	border-style:solid;
	height:0px;
	width:0px;
}


/* .ribbon-edge-bottomleft, */
.ribbon-edge-bottomright {
	top: 50px;
}

/* .ribbon-edge-topleft, */
/* .ribbon-edge-bottomleft {
	left: -10px;
	border-color: transparent #9B1724 transparent transparent;
} */

/* .ribbon-edge-topleft {
	top: -5px;
	border-width: 5px 10px 0 0;
} */
.ribbon-edge-bottomleft {
	border-width: 0 10px 0px 0;
}

.ribbon-edge-topright,
/* .ribbon-edge-bottomright {
	left: 220px;
	border-color: transparent transparent transparent #9B1724;
} */

.ribbon-edge-topright {
	top: 0px;
	border-width: 0px 0 0 10px;
}
.ribbon-edge-bottomright {
	border-width: 0 0 5px 10px;
}
@-webkit-keyframes flow {
	0% { left: -20px; opacity: 0;}
	50% { left: 100px; opacity: 0.3;}
    100%{ left: 180px; opacity: 0;}
}
@keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
.glow {
    position:absolute;
    background: rgb(255,255,255);
    width:40px; 
    height:100%; 
    z-index: 3; 
    animation: flow 1.5s linear infinite;
    -webkit-animation: flow 1.5s linear infinite;
    -moz-animation: flow 1.5s linear infinite;
    transform: skew(20deg); 
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10+, Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* IE10+ */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */ border-left:1px solid #fff;
}
@media (max-width: 768px) {
    .ribbon-front { width: 68px; }
}

</style>
