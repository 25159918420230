<template>
    <div class="drawer-container mask-focused bg-white row position-absolute mx-0 px-2 px-sm-3 px-md-4" :class="{'show-drawer': showDrawer}">
        <div class="welcome-area px-0">
            <div class="welcome-area-content d-flex align-items-center">
                <icon-person-circle width="23" class="flex-shrink-0"/>
                <h5 class="d-inline-block title-16 my-0 ms-2 text-capitalize">
                    {{ isLogged ? `${$t("layout.hi")}  ${ userData.entity.first_name.toLowerCase()}, ${$t("layout.welcome")}!` :$t("layout.welcome") +'!'}}
                </h5>
            </div>
        </div>
        <account-sub-menu class="w-100 px-0" @openConfirmLogout="$emit('openConfirmLogout')" @openLogin="$emit('openLogin')" @openSignIn="$emit('openSignIn')" @closeDrawer="$emit('closeDrawer')"/>
        <hr class="submenu-divider mt-3" />
        <div class="d-flex flex-column px-0">
            <div class="d-flex">
                <h5 class="title-16">{{ $t("layout.my_orders") }}</h5>
            </div>
            <div class="d-flex list-item cursor-pointer" @click.prevent="goTo('/account/orders')"> 
                <icon-order :width="13" class="list-icon fill-dark-gray-200" />
                <span class="list-subtitle size-15 color-dark-gray-200 weight-400 ms-1">{{$t("layout.my_orders")}}</span>
                <IconArrow :width="10" class="arrow-animation"/>
            </div>
        </div>
        <hr class="submenu-divider mt-3" />
        <div class="d-flex flex-column px-0">
            <div class="d-flex">
                <h5 class="title-16">{{ $t("layout.search_by_category") }}</h5>
            </div>
        </div>
        <ul class="categories-list ps-0 mb-0 mt-1">
            <li v-for="({address, value, icon, style, text}) in categoryOptions" :key="address" class="d-flex list-item cursor-pointer" @click.prevent="navigateTo({title: text, value})">
                <component :is="icon" :width="16" height="16" class="list-icon fill-dark-gray-200" :style="style"/>
                <span class="list-subtitle size-15 color-dark-gray-200 weight-400">{{$t(`layout.category.${text}`)}}</span>
                <IconArrow :width="10" class="arrow-animation"/>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'Drawer',
    props: {
        showDrawer: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters({
            userData: "auth/userData",
            isLogged:"auth/isLogged",
        }),
        categoriesENV(){
            if(process.env.VUE_APP_VIDVIE == 1){
                return this.categoriesUS
            } else {
                return this.categoryOptions
            }
        }
    },
    components: {
        IconPersonCircle: () => import('@/common/svg/iconPersonCircle.vue'),
        AccountSubMenu: ()=> import('@/modules/layout/components/navbar/menus/submenus/AccountSubMenu.vue'),
        IconOrder: () => import("@/common/svg/iconOrder.vue"),
        IconArrow: () => import("@/common/svg/iconArrow"),
        IconCategories: () => import("@/common/svg/iconCategories"),

        IconCable: () => import('@/common/svg/categories/iconCable'),
        IconCellphone: () => import('@/common/svg/categories/iconCellphone'),
        IconCharger: () => import('@/common/svg/categories/iconCharger'),
        IconHeadphone: () => import('@/common/svg/categories/iconHeadphone'),
        IconSpeaker: () => import('@/common/svg/categories/iconSpeaker'),

        IconBattery: () => import('@/modules/home/assets/svg/Categories/iconBattery'),
        IconCamera: () => import('@/modules/home/assets/svg/Categories/iconCamera'),
        IconComputer: () => import('@/modules/home/assets/svg/Categories/iconComputer'),
        IconConfiguration: () => import('@/modules/home/assets/svg/Categories/iconConfiguration'),
        IconGlass: () => import('@/modules/home/assets/svg/Categories/iconGlass'),
        IconHandsFree: () => import('@/modules/home/assets/svg/Categories/iconHandsFree'),
        IconLightRing: () => import('@/modules/home/assets/svg/Categories/iconLightRing'),
        IconPhoneCase: () => import('@/modules/home/assets/svg/Categories/iconPhoneCase'),
        IconPowerBank: () => import('@/modules/home/assets/svg/Categories/iconPowerBank'),
        IconSIM: () => import('@/modules/home/assets/svg/Categories/iconSIM'),
        IconSecurityCamera: () => import('@/modules/home/assets/svg/Categories/iconSecurityCamera'),
        IconStorage: () => import('@/modules/home/assets/svg/Categories/iconStorage'),
        IconTablet: () => import('@/modules/home/assets/svg/Categories/iconTablet'),
        IconTools: () => import('@/modules/home/assets/svg/Categories/iconTools'),
        IconTouch: () => import('@/modules/home/assets/svg/Categories/iconTouch'),
    },
    methods: {
        navigateTo(category){
            this.$store.commit("products/SET_CATEGORY", category);
            this.$router.replace({path: '/shop'}).catch(() => {})
        },
        goTo(path) {

            this.$router.push({path}).catch((e) => console.log(e));
        }
    },
    data(){
        return {
            categoryOptions: [
                {
                    viewBox: '0 0 250 250',
                    icon: 'icon-headphone',
                    style: 'margin-top: 2px;',
                    text: 'headphones',
                    value: 16,
                },
                {
                    icon: 'icon-cable',
                    style:'margin-left: 3px; margin-top: 1px;',
                    text: 'cables',
                    value: 9,
                },
                {
                    icon: 'icon-cellphone',
                    style:'margin-top: 3px;',
                    text: 'cellphones',
                    value: 1,
                },
                {
                    viewBox: '20 0 250 250',
                    icon: 'icon-speaker',
                    style:'margin-top: 3px;',
                    text: 'speakers',
                    value: 5,
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 11,
                    text: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 9,
                    text: 'cables'
                },
                {
                    icon: 'IconCellphone',
                    viewBox: "0 0 250 250",
                    value: 1,
                    text: 'cellphones'
                },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 5,
                    text: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 3,
                    text: 'batterys'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "30 10 200 200",
                    value: 11,
                    text: 'charging_cube'
                },
                //
                {
                    icon: 'IconSecurityCamera',
                    viewBox: "0 0 200 200",
                    value: 36,
                    text: 'security_cameras'
                },
                {
                    icon: 'IconCamera',
                    viewBox: "0 0 200 200",
                    value: 36,
                    text: 'cameras'
                },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 12,
                    text: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 25,
                    text: 'touch'
                },
                {
                    icon: 'IconHandsFree',
                    viewBox: "0 0 200 200",
                    value: 16,
                    text: 'handsfree'
                },
                {
                    icon: 'IconComputer',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'computers'
                },
                {
                    icon: 'IconTools',
                    viewBox: "0 0 200 200",
                    value: 31,
                    text: 'tools'
                },
                {
                    icon: 'IconLightRing',
                    viewBox: "0 0 200 200",
                    value: 31,
                    text: 'light_ring'
                },
                {
                    icon: 'IconStorage',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'storage'
                },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 40,
                    text: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 34,
                    text: 'glass'
                },
                {
                    icon: 'IconConfiguration',
                    viewBox: "0 0 200 200",
                    value: 43,
                    text: 'config'
                },
                {
                    icon: 'IconTablet',
                    viewBox: "0 0 200 200",
                    value: 33,
                    text: 'tablet'
                },
                {
                    icon: 'IconSIM',
                    viewBox: "0 0 200 200",
                    value: 47,
                    text: 'SIM'
                }
            ],
            categoriesUS: [
                {
                    icon: 'IconHeadphone',
                    viewBox: "0 0 250 250",
                    value: 4,
                    name: 'headphones'
                },
                {
                    icon: 'IconCharger',
                    viewBox: "0 10 250 250",
                    value: 3,
                    name: 'chargers'
                },
                {
                    icon: 'IconCable',
                    viewBox: "0 0 250 250",
                    value: 	2,
                    name: 'cables'
                },
                // {
                //     icon: 'IconCellphone',
                //     viewBox: "0 0 250 250",
                //     value: 1,
                //     name: 'cellphones'
                // },
                {
                    icon: 'IconSpeaker',
                    viewBox: "0 0 250 250",
                    value: 8,
                    name: 'speakers'
                },
                {
                    icon: 'IconBattery',
                    viewBox: "0 0 200 200",
                    value: 6,
                    name: 'batterys'
                },
                // {
                //     icon: 'IconSecurityCamera',
                //     viewBox: "0 0 200 200",
                //     value: 36,
                //     name: 'security_cameras'
                // },
                {
                    icon: 'IconPhoneCase',
                    viewBox: "0 0 200 200",
                    value: 17,
                    name: 'phone_cases'
                },
                {
                    icon: 'IconTouch',
                    viewBox: "0 0 200 200",
                    value: 21,
                    name: 'touch'
                },
                // {
                //     icon: 'IconTools',
                //     viewBox: "0 0 200 200",
                //     value: 31,
                //     name: 'tools'
                // },
                // {
                //     icon: 'IconStorage',
                //     viewBox: "0 0 200 200",
                //     value: 19,
                //     name: 'storage'
                // },
                {
                    icon: 'IconPowerBank',
                    viewBox: "0 0 200 200",
                    value: 5,
                    name: 'powerbank'
                },
                {
                    icon: 'IconGlass',
                    viewBox: "0 0 200 200",
                    value: 14,
                    name: 'glass'
                },
                // {
                //     icon: 'IconTablet',
                //     viewBox: "0 0 200 200",
                //     value: 33,
                //     name: 'tablet'
                // },
                // {
                //     icon: 'IconSIM',
                //     viewBox: "0 0 200 200",
                //     value: 27,
                //     name: 'SIM'
                // },
            ],
            color: '#FF0000'
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-container {
    padding-top: 16px;
    top: 64px !important;
    left: 0;

    height: calc(100vh - 74px);
    max-width: 325px;

    display: block;
    box-sizing: content-box !important;

    -webkit-box-shadow: 2px 3px 9px 0px #909da41a;
    box-shadow: 2px 3px 9px 0px #909da41a;
    
    transform: translateX(-101%) !important;
    transition: transform .3s;

    overflow-y: auto;
}

.welcome-area {
    margin: 0px;
    margin-bottom: 10px; 

    @media (min-width: $md) {
        margin: 0px -23px;
        margin-bottom: 15px; 
    }
    .welcome-area-content {
        margin-bottom: 10px; 
    }
}

.show-drawer {
    transform: translateX(0%) !important;
}

.categories-list {
    max-height: 135px;
    margin-bottom: 20px;
    overflow: scroll;
    scrollbar-color: var(--gray-100) #edf2f500;
}
</style>