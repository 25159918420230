<template>
    <main-modal @closeModal="$emit('closeModal')">
        <div class="d-flex justify-content-center justify-content-md-start mb-4 px-2">
            <h2 class="title-22 color-blue-700 mb-1">{{$t("account.name_and_code")}}</h2>
        </div>
        <div class="d-flex justify-content-end mb-2 mb-sm-4">
            <input-search :placeholder="this.$t('account.filter_for_name_and_code')" class="mb-0" @search="getClientFilters" 
            @keyupEnter="getClientFilters" @changeValue="(data)=>{search = data}" :value="search"/>
        </div>
        <div class="table-responsive pe-2" v-if="!loading" style="height: 468px;  width: 717px;">
            <table class="table table-hover" v-if="!loadingCode">
                <thead>
                    <td class="text-center p-2">{{$t('account.code')}}</td>
                    <td class="p-2">{{$t('account.name')}}</td>
                    <td class="p-2">{{$t('account.company')}}</td>
                </thead>
                <tbody>
                    <tr v-for="client in clientData" :key="client.code" @click="selectClient(client.code)" class="cursor-pointer">
                        <td class="weight-600">{{client.code | textFormat}} </td>
                        <td class="text-capitalize">
                            {{`${client.first_name}  ${client.last_name}`  | textFormat}} 
                        </td>
                        <td>{{client.company_name | textFormat}} </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center align-items-center h-100" v-else>
                <half-circle-spinner :animation-duration="1000" :size="40" color="var(--main-red)"/>
            </div>
        </div>
        <lottie :options="defaultOptions" :height="250" :width="250" v-else/>
    </main-modal>
</template>
<script>
import MainModal from '../../../../common/components/modals/MainModal.vue';
import InputSearch from '../../../../common/components/inputs/InputSearch.vue';
import Lottie from 'vue-lottie';
import animationData from '../../assets/animations/searching1.json';
import { mapGetters } from 'vuex';
import { CustomerService } from '../../services/customer-service';
const service = new CustomerService();
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    name: "ClientTable",
    components: {
        MainModal,
        InputSearch,
        HalfCircleSpinner,
        Lottie,
    },
    props: {
        customerCode: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/userData",
            branches: "branches/branches",
        }),
    },
    data(){
        return {
            defaultOptions: { animationData: animationData, loop: true },
            clientData: [],
            loading: true,
            loadingCode: false,
            search: '',
        }
    },
    methods:{
        setSearch(value) {
            this.search = value;
        },
        async selectClient(code){
            // this.$emit('closeModal')
            if(this.customerCode){
                const error = {
                    show: true,
                    title: `Quit Client`,
                    description: 'Deselect the selected customer.',
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', error)
                return;
            }
            try{
                this.loadingCode = true;
                await this.$store.dispatch("auth/loginCustomer", {customer: code, getCart: true})
                this.$emit('closeModal')
                const notify ={
                    type: 'success',
                    show: true,
                    contentHTML: `<p class="color-dark-gray-400 subtitle-14 m-0"> ${this.$t('common.notification.data_obtained_sucessfully')}: <b class="text-capitalize">
                        ${this.user.entity.first_name.toLowerCase()} ${this.user.entity.last_name.toLowerCase()}</b> 
                        (<span class="color-main-navy">${this.user.entity.code}</span>)<p>`
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
            } catch (error) {
                const errorData = {
                    show: true,
                    title: `Ocurrio un error obteniendo los datos.`,
                    contentHTML: 
                    `<div class="text-center color-dark-gray-300">
                        <span class="ms-1">${ this.$options.filters.textFormat(error.message)}</span>
                    </div>`
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
            } finally{
                this.loadingCode = false;
            }

        },
        async getClientFilters(){
            try {
                this.loading = true                
                const response = await service.getClient(this.search)
                this.clientData = response
            } catch (error) {
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true, 
                    title: `Ocurrio un error obteniendo los datos.`, 
                    description: error.response.data.message
                })
            } finally{
                this.loading = false       
            }
        },
    }, 
    async created() {
        await this.getClientFilters()
    }
}
</script>

<style scoped>
    .table > :not(:first-child){
        border-top: 0;
    }
    .table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
        position: sticky;
    }
    .table-hover>tbody>tr:hover>*{
        --bs-table-accent-bg: var(--light-gray-100)
    }
</style>