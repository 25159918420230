<template>
    <icon-base viewBox="0 0 200 200" :width="size" :color="color" >
        <g id="Layer_12">
            <g>
                <g>
                <ellipse class="st0" cx="91.9" cy="169.5" rx="91.7" ry="7.5"/>
                <g>
                    <g>
                        <path class="st1" d="M151.3,55.2c-1.2,0-2.2-1-2.2-2.2V25.1c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2V53       C153.4,54.2,152.5,55.2,151.3,55.2z"/>
                    </g>
                    <g>
                        <path class="st1" d="M164.5,61c-0.6,0-1.1-0.2-1.5-0.6c-0.8-0.8-0.8-2.2,0-3L184.2,36c0.8-0.8,2.2-0.8,3,0s0.8,2.2,0,3       L166,60.3C165.6,60.8,165,61,164.5,61z"/>
                    </g>
                    <g>
                        <path class="st1" d="M197.7,74.4h-27.9c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h27.9c1.2,0,2.2,1,2.2,2.2S198.9,74.4,197.7,74.4z"/>
                    </g>
                </g>
                </g>
                <g>
                    <path class="st2" d="M134.5,60.2c-14.7,0-26.7,11.9-26.7,26.7s11.9,26.7,26.7,26.7s26.7-11.9,26.7-26.7S149.2,60.2,134.5,60.2z      M134.3,99.4c-5.5,0-8.5-5.1-8.5-12.4c0-7.5,3.2-12.6,8.8-12.6c5.8,0,8.5,5.2,8.5,12.3C143.2,94.5,140.1,99.4,134.3,99.4z"/>
                    <path class="st2" d="M134.5,77.9c-2.4,0-4.1,3-4.1,9c0,5.9,1.6,9,4.1,9c2.7,0,4.1-3.3,4.1-9.1C138.6,81.2,137.3,77.9,134.5,77.9z     "/>
                </g>
                <g>
                    <path class="st2" d="M134.5,60.2c-14.7,0-26.7,11.9-26.7,26.7s11.9,26.7,26.7,26.7s26.7-11.9,26.7-26.7S149.2,60.2,134.5,60.2z      M134.3,99.4c-5.5,0-8.5-5.1-8.5-12.4c0-7.5,3.2-12.6,8.8-12.6c5.8,0,8.5,5.2,8.5,12.3C143.2,94.5,140.1,99.4,134.3,99.4z"/>
                    <path class="st2" d="M134.5,77.9c-2.4,0-4.1,3-4.1,9c0,5.9,1.6,9,4.1,9c2.7,0,4.1-3.3,4.1-9.1C138.6,81.2,137.3,77.9,134.5,77.9z     "/>
                </g>
                <g>
                    <path class="st1" d="M68.4,149.1c-5.3,0-9.7,4.3-9.7,9.7c0,5.3,4.3,9.7,9.7,9.7s9.7-4.3,9.7-9.7     C78.1,153.4,73.8,149.1,68.4,149.1z"/>
                    <path class="st1" d="M111.8,150.1c-5.3,0-9.7,4.3-9.7,9.7c0,5.3,4.3,9.7,9.7,9.7c5.3,0,9.7-4.3,9.7-9.7     C121.5,154.5,117.1,150.1,111.8,150.1z"/>
                    <path class="st1" d="M127.9,124.3l2-11.2c-12.5-2.2-22.1-13.1-22.1-26.3c0-0.6,0-1.2,0.1-1.8H52.5l-1.1-7.8     c-0.3-2.3-2.3-4.1-4.7-4.1H25.3c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h19c1.8,0,3.4,1.4,3.6,3.2l8.2,60.7     c0.3,2.3,2.3,4.1,4.7,4.1h0.1l63.5-1.4c1,0,1.9-0.9,1.9-1.9v-0.1c0-1.1-0.9-1.9-2-1.9l-61,1.4c-1.8,0.1-3.5-1.3-3.7-3.2l-1-7.4     h62.1C124.3,130.4,127.3,127.9,127.9,124.3z M99.5,96.9c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2     S97.8,96.9,99.5,96.9z M82.6,96.9c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2S80.8,96.9,82.6,96.9z      M103,118.3c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.1-0.9-0.4c-2.6-2.9-6.3-4.5-10.2-4.5c-3.9,0-7.6,1.7-10.2,4.5     c-0.5,0.5-1.2,0.6-1.7,0.1s-0.6-1.2-0.1-1.7c3-3.4,7.4-5.4,12-5.4s9,2,12,5.4C103.5,117.1,103.5,117.8,103,118.3z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconCartEmpty',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st2{fill:#CF2127;}
	.st0{fill:#F0F4F9;}
	.st1{fill:#C6D4DB;}
  
</style>
