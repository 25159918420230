<template>
    <cart-filled 
        class="cart-filled" 
        v-if="quantity >= 1" @openConfirm="openConfirm" :manyProducts="cartProducts.length >= 4" 
        @buy="()=>{$emit('buy')}" 
        @createQuotation="()=>{$emit('createQuotation')}" 
        :loading="loading" 
    />
    <cart-empty class="cart-empty" v-else/>
</template>

<script>
import CartEmpty from './CartEmpty.vue'
import CartFilled from './CartFilled.vue'
import { mapGetters } from "vuex"
export default {
    name: 'CartSubMenuContainer',
    components: {
        CartFilled,
        CartEmpty,
    },
    props:{
        loading:{
            type: Boolean
        }
    },
    computed: {
        ...mapGetters({
            quantity: 'cart/quantityProducts',
            cartProducts: "cart/getProducts"
        }),
    },
    methods: {
        openConfirm(id){
            this.$emit('openConfirm', id);
        },
    },
}
</script>

<style lang="scss" scoped>

.cart-empty {
    width:  280px !important;
}

</style>