<template>
    <main-confirm @confirm="$emit('confirm')" :isQuestion="false" @cancel="$emit('closeModalLogout')" confirm-title="auth.remove_client" 
    :buttonConfirmTitle="btnText" :secondTitle="`${user ? user.entity.first_name : ''} ${user ? user.entity.last_name : ''}?`" 
    :description="$t('auth.are_you_sure_you_want_to_remove_the_client')" :loading="loading">
        <icon-logout :size="80"/>
    </main-confirm>
</template>

<script>
import { mapGetters } from 'vuex';
import MainConfirm from '../../../common/components/modals/MainConfirm.vue';

export default {
    name: "ConfirmLogout",
    components: {
        MainConfirm,
        IconLogout: () => import("@/modules/auth/assets/svg/iconLogout"),
    },
    props:{
        loading:{
            type: Boolean
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/userData"
        })
    },
    data() {
        return {
            btnText: 'auth.accept'
        }
    },
}
</script>

<style scoped>
.main-confirm::v-deep > .modal-main > p > span{
    color: var(--main-red) !important;
}
</style>