<template>
    <svg :width="size + 'px'" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M141.666 141.666L58.333 58.333" stroke="#364B5D" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M58.333 141.666V58.333H141.666" stroke="#364B5D" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
name: 'iconCopySuggestion',
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
}
}
</script>

<style scoped>

</style>
