<template>
    <div class="mobile-search-container d-flex flex-column position-absolute px-0">
        <div class="position-relative container px-0 bg-white h-100" style="z-index: 11; border-bottom: 2px solid" :style="{'border-bottom-color': searchHistory.length >= 1 ? 'var(--gray-1001)':'white'}">
            <div class="row mx-0 h-100">
                <div class="col-12 px-0 h-100">
                    <form  id="main-search-mobile" class="d-flex align-items-center h-100" @submit.prevent="">
                        <div class="close-suggestions d-flex align-content-center cursor-pointer p-2 ms-2" @click="$emit('toggleSearchBar')">
                            <icon-back-arrow :size="18" color="var(--light-main-navy)"/>
                        </div>
                        <input v-model="selection" type="text" class="form-control search-input size-17 ps-2 pe-0 pt-0 h-100" id="mobileInputSearch" name="search" :placeholder="$t('layout.search_products')+'...'" 
                        @keydown.down='down' @keydown.up='up' @keydown.enter.once="enterMobile" @input='change' autocomplete="off"/>
                        <div v-if="showDeleteSelection" @click="deleteSelection" class="delete-selection d-flex align-content-center justify-content-center cursor-pointer position-relative my-auto px-2" :class="{'active': activeDeleteSelection}" >
                            <icon-delete-suggestion class="position-relative" style="z-index: 4;" color="var(--light-main-navy)" :size="14" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="search-bg position-absolute w-100 bg-dark-main-navy" @click="$emit('toggleSearchBar')" />
        <div v-if="openSuggestion" class="mobile-suggestions-container position-absolute px-0" style="z-index: 11;">
            <div class="container px-0">
                <div class="row mx-0">
                    <div class="header-search-mobile col-12 pt-1 px-0">
                        <ul class="suggestion-list" id="suggestion">
                            <li v-for="(suggestion, index) in suggestions" :key="index" :class="[suggestionState(index)]" class="mobile-suggestion-element d-flex justify-content-between cursor-pointer ps-3 ms-0"
                                data-toggle="tooltip" data-placement="top" :title="suggestion.description | textFormat" >
                                
                                <div v-if="isInHistory(suggestion)" @touchstart="detectClick(index)" @touchend="removeClick" class="d-flex align-content-center me-2 pe-1">
                                    <icon-history class="icon-history" :size="23" color="var(--light-main-navy)" />
                                </div>
                                <div @touchstart="detectClick(suggestion)" @touchend="removeClick" class="mobile-suggestion-element-text flex-grow-1 text-limited py-2" @click.prevent="selectSuggestion(index, suggestion.description)">
                                    <a href="#" class="suggestion-address color-dark-main-navy size-16">{{ suggestion.description | textFormat}}</a>
                                </div>
                                <div class="copy-suggestion p-2 cursor-pointer" @click="copySelection(suggestion.description)">
                                    <icon-copy-suggestion :size="24" color="var(--light-main-navy)"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mobile-suggestions-container position-absolute px-0" style="z-index: 11;">
            <div class="container px-0">
                <div class="row mx-0">
                    <div class="header-search-mobile col-12 pt-1 px-0">
                        <ul class="suggestion-list">
                            <li v-for="(history, index) in searchHistory" :key="index" :class="[suggestionState(index)]" class="mobile-suggestion-element d-flex justify-content-between cursor-pointer ps-2 ms-0"
                                data-toggle="tooltip" data-placement="top" :title="history.description | textFormat">
                                <div @touchstart="detectClick(history)" @touchend="removeClick" class="d-flex align-content-center ms-2">
                                    <icon-history class="icon-history" :size="23" color="var(--light-main-navy)"/>
                                </div>
                                <div @click="selectHistory(index, history.description)" @touchstart="detectClick(history)" @touchend="removeClick" class="mobile-suggestion-element-text flex-grow-1 text-limited py-2 ps-2 ms-1">
                                    <a href="#" class="suggestion-address color-dark-main-navy size-16">{{ history.description | textFormat}}</a>
                                </div>
                                <div class="copy-suggestion p-2 cursor-pointer" @click="copySelection(history.description)">
                                    <icon-copy-suggestion :size="24" color="var(--light-main-navy)"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { searchMixin } from "@/modules/layout/mixins/search-mixin.js";

import IconBackArrow from '../../../../assets/svg/iconBackArrow.vue';
import iconCopySuggestion from '../../../../assets/svg/iconCopySuggestion.vue';
import IconDeleteSuggestion from '../../../../assets/svg/iconDeleteSuggestion.vue';
import IconHistory from '../../../../../products/assets/svg/iconHistory.vue';
export default {
    name: 'MobileSearch',
    components: {
        IconBackArrow,
        iconCopySuggestion,
        IconDeleteSuggestion,
        IconHistory
    },
    data() {
        return {
            showDeleteSelection: false, //if there's something written on the search-bar we will show the 'x' icon
            activeDeleteSelection: false, //the styles of the icon 'while is being pressed'
            deleteSectionCounter: null, //the counter for increase performance.
            //
            searchCounter: null,
            holdCounter: null,
            isNav: true, //in order to fetch the products correctly.
        }
    },
    mixins: [searchMixin],
    props: {
        mobileSearch: {
            type: Boolean
        },
    },
    methods: {
        detectClick(suggestion){
            if (this.isInHistory(suggestion)) { // if the index is on the searchHistory
                clearTimeout(this.holdCounter);
                this.holdCounter = setTimeout(() => {
                    this.$emit('deleteHistory', suggestion.id)
                }, 800);
            }
        },
        removeClick(){
            clearTimeout(this.holdCounter);
        },
        suggestionState(index){
            let suggestion;
            if (this.suggestions.length === 1) {
                suggestion = index === this.current ? 'active-hard':'';
            }else {
                suggestion = index === this.current ? 'active-soft':''; 
            }
            return suggestion;
        },
        isOnlyActive(index){
            return index === this.current && this.suggestions.length === 1;
        },
        enterMobile(){
            this.enter();
            this.searchCounter = setImmediate(() => {
                this.$emit('toggleSearchBar');
            });
        },
        selectSuggestion(index, suggestion) {
            this.copySelection(suggestion);
            this.suggestionClick(index);

            this.searchCounter = setImmediate(() => {
                this.$emit('toggleSearchBar');
            });
        },
        selectHistory(index, history) {
            this.copySelection(history);
            this.historyClick(index);
            
            this.searchCounter = setImmediate(() => {
                this.$emit('toggleSearchBar');
            });
        },
        deleteSelection(){
            this.activeDeleteSelection = true;
            this.deleteSectionCounter = setTimeout(() => {
                this.selection = '';
                this.activeDeleteSelection = false;
            }, 150)
        },
        copySelection(suggestion){
            this.selection = this.$options.filters.textFormat(suggestion);            
        }
    },
    mounted(){
        clearTimeout(this.deleteSectionCounter);
        clearImmediate(this.searchCounter); // for solving the bug where it doesnt saves the search-selection on touch close.
    },
}
</script>

<style scoped>
.search-bg {
    top: 0px;

    height: 100vh;
    opacity: 0.62;
    z-index: 10;
}
.mobile-search-btn {
    right: 0;
    border: 0;

    width: 60px;    
    height: 44px;
}
.mobile-search-container {
    top: 63px;
    left: 0;

    width: 100%;
    height: 56px;

    z-index: 12;

    transition: var(--transition-1);
}
form {
    height: 42px;
}
.mobile-suggestions-container {
    top: 45px !important;
    width: 100% !important;
    background: transparent;
}
.header-search-mobile {
    width: 100%;
    padding-top: 0 !important;
}
@keyframes dropdown-animation {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.suggestion-list {
    position: relative;
    display: block;
    top: 11px;
    left: 0;
    list-style-type: none;
    padding-left: 0 !important;
    background-color: white;
    transition: all 0.15s ease-in-out !important;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;

    max-height: 272px;
    overflow: scroll;
}
.suggestion-list > li:active {
    background: var(--light-gray-501);
}
.mobile-suggestion-element {
    margin-left: 0 !important; 
    margin-right: 0 !important;

    padding-right: 8px !important;
}
.mobile-suggestion-element.active-hard {
    background-color: var(--light-gray-501);
}
.mobile-suggestion-element.active-soft {
    background-color: var(--light-gray-50);
}
.mobile-suggestion-element-text {
    padding-right: 14px;
}
.mobile-suggestion-element:hover::v-deep svg.icon-history path, .mobile-suggestion-element:active::v-deep svg.icon-history path{
    fill: var(--blue-600) !important;
}
.mobile-suggestion-element-text:hover a, .mobile-suggestion-element-text:active a{
    color: var(--blue-600) !important;
}
.mobile-suggestion-element:hover a {
    color: var(--blue-600) !important;
}
.search-input {
    width: 100%;
    border: unset; 
    margin-right: 6px;
}
.delete-selection {
    margin-right: 28px !important;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    
    height: 34px;

    transition: var(--transition-1) !important;
}
.delete-selection.active {
    border-radius: 3.123rem;
    background-color: var(--gray-100);

    transition: var(--transition-1) !important;
}
.close-suggestions {
    height: 34px;
}
.copy-suggestion, .close-suggestions {
    border-radius: 3.123rem;
    transition: var(--transition-1) !important;
}
.copy-suggestion:active, .close-suggestions:active {
    background-color: var(--gray-50);
    transition: var(--transition-1) !important;
}

@media (min-width: 992px) {
    .suggestion-list {
        display: none;
    }
}
</style>