<template>
    <div class="d-flex flex-column justify-content-stretch">
        <ul class="p-0 w-100">
            <li v-for="{product, quantity} in cartProducts" :style="{marginRight: cartProducts.length >= 4 ? '12px':'0px'}" :key="product.codarticulo" class="d-flex align-content-center py-3 color-dark-main-navy">
                <router-link :to="{ name: 'product-detail', params: { id: product._id }}" class="cart-product-image-father-container">
                    <div class="cart-product-image-container d-flex align-items-center" :class="{'opacity-2': hoveredProduct == product.codarticulo}" @mouseover="hoveredProduct = product.codarticulo" @mouseleave="hoveredProduct = null">
                        
                        <image-product :source="getImages(product.imageGallery)" :styleObject="{ width: '100%', height: '100%', objectFit: 'cover' }" />
                    </div>
                </router-link>
                <div class="cart-product-info-container d-flex flex-column py-4 px-3 line-height-130">
                        <router-link tag="span" :to="{ name: 'product-detail', params: { id: product._id }}" @mouseover.native="hoveredProduct = product.codarticulo" @mouseleave.native="hoveredProduct = null" 
                        :class="{'hovered-desc': hoveredProduct == product.codarticulo}"
                        class="size-14 weight-500 text-limited product-desc"
                        data-toggle="tooltip" data-placement="top" :title="product.description | textFormat">
                            {{product.description | uppercasefirst}}
                        </router-link>
                    <span class="size-14 weight-600"> <!-- hay que crear el filtro de los slice -->
                        {{product.price | moneyFormat}} &times; {{quantity}}
                    </span>
                </div>
                <div class="cart-product-trash d-flex align-content-center" @click="openConfirm(product.codarticulo)" > 
                    <icon-trash :size="25" class="fill-gray-1001 hover-fill-dark-main-red cursor-pointer"/>
                </div>
            </li>
        </ul>
        <div class="d-flex flex-column button-container" :style="{marginRight: cartProducts.length >= 4 ? '23px':'0px'}">
            {{selectedQuotation}}
            <div class="d-flex">
                <span class="size-15 weight-500 me-auto color-dark-gray-200">Subtotal:</span>
                <span class="size-15 weight-700">{{cart.total_amount | moneyFormat}}</span>
            </div>
            <div class="d-flex" style="margin-top: 35px;">
                <router-link to="/cart" class="d-flex" replace>
                    <second-button :class="[manyProducts ? 'alt-width':'normal-width']" title="layout.go_cart" icon>
                        <icon-cart :size="15" class="me-1 fill-dark-main-navy"/>
                    </second-button>
                </router-link>
                <main-button class="btn-main-flex ms-auto" style="width: 130px" icon :title="bottonLabel()" @click="handlerBuy" 
                :disabled="loading || bottonLabel() == 'cart.verifying'" :class="{'btn-refresh-quotation':isChanged}">
                    <half-circle-spinner :animation-duration="1000" :size="13" color="#fff" v-if="bottonLabel() == 'cart.verifying'" class="me-1"/>
                    <icon-refresh color="#fff" v-else-if="isChanged" :size="13" class="me-1"/>
                    <icon-buy :size="13" color="var(--white)" class="me-1" v-else/>
                </main-button>
            </div>
        </div>   
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { HalfCircleSpinner } from 'epic-spinners'
import IconRefresh from '../../../../../../../common/svg/iconRefresh.vue';
import IconBuy from '../../../../../../../common/svg/iconBuy.vue'
import IconCart from '../../../../../../../common/svg/iconCart.vue'
import IconTrash from '../../../../../../../common/svg/iconTrash.vue'
import MainButton from '../../../../../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../../../../../common/components/buttons/SecondButton.vue'
import ImageProduct from '../../../../../../products/components/Image.vue'
import { cartMixin } from "../../../../../../cart/helpers/mixins/cart-mixin";
import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";

export default {
    name:'CartFilled',
    components:{
        HalfCircleSpinner,
        IconRefresh,
        IconTrash,
        IconCart,
        ImageProduct,
        SecondButton,
        MainButton,
        IconBuy,
    },
    props: {
        manyProducts: {
            type: Boolean
        },
        loading: {
            type: Boolean
        } 
        
    },
    computed: {
        ...mapGetters({
            cartProducts: 'cart/getProducts',
            amount: 'cart/amount',
            cart: 'cart/cart',
            employeeActiveCode: 'auth/employeeActiveCode',
            selectedQuotation: "account/selectedQuotation"
        }),
        isChanged(){
            let index = this.changedQuotes.findIndex(item=> item.quotation == this.selectedQuotation)
            return index != -1
        }
    },
    mixins: [cartMixin, productMixin],
    data(){
        return{
            hoveredProduct: null,
            // loading: false
        }
    },
    methods:{
        openConfirm(codarticulo) {
            this.$emit('openConfirm', codarticulo)
        },
        navigateToProduct(id){
            this.$router.replace({ name: 'product-detail', params: { id }})
        },
        async handlerBuy(){
            if((this.employeeActiveCode && !this.selectedQuotation) || this.isChanged){
                this.$emit('createQuotation')
            } else{
                this.$emit('buy')
            }
        },
        bottonLabel(){
            if(this.employeeActiveCode){
                return this.loading ? 'cart.verifying'
                    : this.isChanged ? 'cart.refresh_cart'
                    : this.selectedQuotation ? 'cart.to_purchase' 
                    : 'cart.to_quote'
            }else{
                return this.loading ? 'cart.verifying' : 'cart.to_purchase';
            }
        }
    },
}
</script>

<style scoped>
    .btn-refresh-quotation.main-button::v-deep{
        background-color: var(--blue-600);
    }
    .btn-refresh-quotation.main-button::v-deep:hover{
        background-color: #689CEE;
    }
    .btn-refresh-quotation.main-button::v-deep.pressed{
        background-color: var(--blue-700) !important;
    }

    ul {
        max-height: 340px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: var(--gray-100) #edf2f5 0;
    }
    ul li:first-child {
        padding-top: 0 !important;
    }
    ul li {
        list-style: none;
        border-bottom: 1px solid var(--gray-100);
    }
    ul li .cart-product-image-father-container {
        height: 85px;
        width: 85px;
    }
    ul li .cart-product-image-container {
        overflow: hidden;
        min-width: 85px !important;
        height: 85px !important;
        border: 1px solid var(--gray-100);
        border-radius: 5px;
        transition: var(--transition-1);
    }
    ul li .cart-product-image-container:hover {
        cursor: pointer;
    }
    ul li .cart-product-info-container .product-desc {
        width: 133px;
        color: var(--dark-gray-200);
        transition: var(--transition-1);
    }
    .cart-product-image-container:hover .image__wrapper::v-deep {
        transform: scale(1.1);
    }
    .cart-product-image-container .image__wrapper::v-deep {
        transition: var(--transition-1);
    }
    .hovered-desc {
        cursor: pointer;
        color: var(--main-red) !important;
    }
    .button-container .button-secondary {
        line-height: 30px !important;
        border: 1.5px solid var(--dark-main-navy);
    }
    .button-secondary:hover {
        border-color: var(--main-red)
    }
    .button-container .button-secondary:hover svg {
        fill: var(--main-red) !important;
    }
    .button-container .button-secondary.normal-width {
        width: 130px;
    }
    .button-container .button-secondary.alt-width {
        width: 124px;
    }
    .button-container .btn-main svg {
        margin-top: -4px;
    }
    .button-container .btn-main.normal-width {
        width: 130px;
    }
    .button-container .btn-main.alt-width {
        width: 124px;
    }
</style>
